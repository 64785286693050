// banner used for corporate workshops and school programs
import React from "react";
import styled from "styled-components";
import { mainWhite, screen } from "../../components/common/variables";

const Wrapper = styled.div`
  background: ${mainWhite};

  .inner-wrapper {
    padding: 41px 0 22px 0;
    display: flex;
    text-align: center;
    flex-direction: column;
    @media ${screen.xsmall} {
      padding: 0 0 0 0;
      align-items: center;
      flex-direction: row;
    }
    @media ${screen.large} {
      padding: 0 0 0 0;
    }

    .img-col {
      width: 100%;
      display: none;
      @media ${screen.xsmall} {
        width: 30%;
        display: block;
      }

      &--mobile {
        display: block;
        height: 255px;
        @media ${screen.xsmall} {
          display: none;
        }
      }
    }

    .txt-col {
      padding: 0 0 32px 0;
      @media ${screen.xsmall} {
        width: 40%;
        padding: 0 20px;
      }
    }
  }
`;

const ThreeColumnBanner = ({ children, className }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

export default ThreeColumnBanner;
